<template>
    <div
        v-if="!!record"
        class="walker"
    >
        <div
            class="tile tile-centered list-tile"
        >
            <div class="tile-icon">
                <open-icon glyph="user" size="lg" library="hop" />
            </div>
            <div class="tile-content">
                <div class="tile-title">
                    {{ record.first_name }} {{ record.last_name }}<span v-if="isCaptain"> ({{ $root.translate("Captain") }})</span>
                </div>
                <div class="tile-title">
                    <div class="columns text-small">
                        <div class="column col-auto">
                            {{ record.computed_total_trips | nibnut.number("0,0") }}
                            {{ $root.translate("trip:::trips", {}, record.computed_total_trips) }}
                        </div>
                        <div class="column col-auto">
                            <span v-if="total_distance < 1000">
                                {{ total_distance | nibnut.number("0,0") }}<span> m</span>
                            </span>
                            <span v-else>
                                {{ total_distance / 1000 | nibnut.number("0,0.0") }}<span> km</span>
                            </span>
                        </div>
                        <div class="column col-auto">
                            {{ record.travel_days.length | nibnut.number("0,0") }}
                            {{ $root.translate("days") }}
                        </div>
                        <div class="column col-auto">
                            {{ steps | nibnut.number("0,0") }}
                            {{ $root.translate("steps") }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isCaptain" class="tile-action">
                <default-button
                    @click.prevent="remove"
                >
                    <open-icon glyph="close" size="lg" library="hop" />
                </default-button>
            </div>
        </div>
    </div>
</template>

<script>
import { profile_utilities } from "@/nibnut/mixins"
import { calculator_utilities } from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Member",
    mixins: [profile_utilities, calculator_utilities],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        remove () {
            this.$emit("delete", this.record)
        }
    },
    computed: {
        total_distance () {
            if(!this.record) return 0
            return this.standardized_distance(this.record.computed_total_distance)
        },
        steps () {
            if(!this.record) return 0
            return this.steps_for_distance(this.record.computed_total_distance)
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        isCaptain: {
            type: Boolean,
            default: false
        }
    }
}
</script>
